.no-match {
    text-align: center;
}

.no-match__title {
    padding: 0;
}

.no-match__p {
    margin: 3rem 0 4rem 0;
}
