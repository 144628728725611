.img {
    display: block;
    object-fit: cover;
    margin: 1.75rem 0;
    border-radius: 28px;
    width: 100%;
    height: auto;
}

.img--start {
    margin: 2rem 0;
    height: calc(100vh - 35rem);
}

.img--card {
    margin: 0;
    border-radius: 28px 28px 0 0;
}

.img--no-match {
    object-fit: contain;
    max-height: calc(100vh - 35rem);
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    object-fit: cover;
}

.logo {
    margin: 0;
    width: auto;
    height: 3.5rem;
    padding: 0;
}