.contact {
    background: rgba(var(--primary-color), 5%);
}

.contact-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.contact-list__li {
    display: flex;
    align-items: flex-start;
}

.contact-list__li:not(:last-child) {
    display: flex;
    margin: 0 0 1.25rem 0;
}

.contact-list__img {
    margin: 0 0.75rem 0 0;
    width: 1.5rem;
    height: 1.5rem;
}

.contact__map {
    margin: 1.75rem 0 0 0;
    border: none;
    border-radius: 28px;
    width: 100%;
    min-height: 13.375rem;
}
