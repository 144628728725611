.nav {
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 16%);
    width: 100%;
    padding: 0;
    background: var(--bg-color);
}

.menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    list-style-type: none;
    margin: 0;
    padding: 0 1.5rem;
    background: rgba(var(--primary-color), 8%);
}

.menu__link {
    transition: all 0.15s ease;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 0 1rem 0;
    text-decoration: none;
    color: var(--text-on-bg-color);
}

.menu__icon {
    transition: all 0.15s ease;
    background: rgba(var(--primary-color), 0);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 0 0.25rem 0;
    border-radius: 1rem;
    width: 4rem;
    height: 2rem;
}

.menu__icon--active {
    background: rgba(var(--primary-color), 12%);
}
