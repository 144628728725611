.header {
    z-index: 999;
}

.header__content {
    box-shadow: 0 0 3px rgba(0, 0, 0, 16%);
    padding: 0.875rem 1.5rem;
    background: rgba(var(--primary-color), 5%);
}

.header__link {
    display: flex;
}