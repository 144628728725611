:root {
    --bg-color: #fcfcff;
    --primary-color: 5, 94, 178;
    --text-on-primary-color: #ffffff;
    --text-on-bg-color: #1c1b1f;
    --border-color: #74777f;

    --body-font: 400 1rem/1.5rem "Roboto", sans-serif;
    --display-font: 400 2.25rem/2.75rem "Roboto", sans-serif;
    --headline-font: 400 1.75rem/2.25rem "Roboto", sans-serif;
    --title-font: 500 1rem/1.5rem "Roboto", sans-serif;

    scroll-behavior: smooth;
}

body {
    margin: 0;
    background: var(--bg-color);
    font: var(--body-font);
    color: var(--text-on-bg-color);
}

.link {
    text-decoration: none;
    color: var(--text-on-bg-color);
}

.link--visible {
    text-decoration: underline;
}

.link:hover {
    text-decoration: underline;
}

.display-title {
    margin: 0;
    padding: 0 0 1rem 0;
    font: var(--display-font);
}

.section {
    padding: 2.25rem 1.5rem;
}

.section__headline {
    margin: 0 0 1.75rem 0;
    padding: 0;
    font: var(--headline-font);
}

.p {
    margin: 0;
    padding: 0;
}

.p--spaced {
    margin: 0 0 1rem 0;
}

.subsection:not(:last-child) {
    margin: 0 0 1.25rem 0;
    padding: 0;
}

.subsection__headline {
    margin: 0 0 0.5rem 0;
    padding: 0;
    font: var(--title-font);
}

.subsection__p {
    margin: 0 0 1rem 0;
}

.button {
    transition: all 0.15s ease;
    display: inline-block;
    border: none;
    border-radius: 100px;
    padding: 0.5rem 1.5rem;
    background: rgb(var(--primary-color));
    cursor: pointer;
    text-decoration: none;
    font: var(--body-font);
    color: var(--text-on-primary-color);
}
