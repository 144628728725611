@media only screen and (min-width: 48rem) {
    :root {
        --display-font: 400 3.5625rem/4rem "Roboto", sans-serif;
        --headline-font: 400 2rem/2.5rem "Roboto", sans-serif;
        --title-font: 400 1.375rem/1.75rem "Roboto", sans-serif;
    }

    .section,
    .footer {
        padding: 6.25rem 1.5rem;
    }

    .privacy-policy {
        padding: 6.25rem 1.5rem 0 1.5rem;
    }

    .section__headline {
        margin: 0 0 1.5rem 0;
    }

    .subsection:not(:last-child) {
        margin: 0 0 1.5rem 0;
    }

    .header {
        position: sticky;
        top: 0;
        bottom: 0;
        background: var(--bg-color);
    }

    .header__content {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        background: rgba(var(--primary-color), 5%);
    }

    .header__link {
        flex: 0 0 15%;
    }

    .nav {
        position: static;
        flex: 0 0 22rem;
        box-shadow: none;
        padding: 0;
    }

    .menu {
        align-items: center;
        height: 100%;
        padding: 0;
        background: rgba(var(--primary-color), 5%);
    }

    .menu__item:first-child {
        display: none;
    }

    .menu__icon {
        display: none;
    }

    .menu__link {
        box-sizing: border-box;
        border-radius: 100px;
        padding: 0.5rem 1.5rem;
    }

    .menu__link:hover {
        background: rgba(var(--primary-color), 12%);
    }

    .offer {
        display: grid;
        grid: auto 1fr 1fr / 1fr 1fr;
        gap: 2rem;
    }

    .offer__headline {
        grid-area: 1 / 1 / 2 / 3;
        margin: 0;
    }

    .card--main {
        grid-area: 2 / 1 / 4 / 2;
    }

    .card__img--main {
        height: 16rem;
    }

    .img--card-main {
        height: 100%;
    }

    .card__img--wide {
        flex: 1 1 30%;
    }

    .img--card-wide {
        border-radius: 28px 0 0 28px;
        height: 100%;
    }

    .card,
    .card:not(:last-child) {
        margin: 0;
    }

    .card--wide {
        display: flex;
        flex-flow: row wrap;
    }

    .card__section--wide {
        flex: 1 1 60%;
    }

    .card--1 {
        grid-area: 2 / 2 / 3 / 3;
    }

    .card--2 {
        grid-area: 3 / 2 / 4 / 3;
    }

    .start {
        display: grid;
        grid: 1fr repeat(3, auto) 1fr / 1fr 1fr;
        column-gap: 2rem;
    }

    .start__headline {
        grid-area: 2 / 1 / 2 / 1;
        padding: 0 0 1.5rem 0;
        max-width: 31rem;
    }

    .start__p {
        grid-area: 3 / 1 / 3 / 1;
        max-width: 31rem;
        padding: 0 0 5.625rem 0;
    }

    .start__button {
        grid-area: 4 / 1 / 4 / 1;
        position: relative;
        width: 6.5rem;
    }

    .start__button:before {
        transition: all 0.2s ease;
        transform-origin: 0 50%;
        position: absolute;
        content: "";
        background: rgb(var(--primary-color));
        top: 0;
        left: 0;
        width: 9.5rem;
        height: 2.5rem;
        border-radius: 100px;
        z-index: -1;
    }

    .start__button:hover:before {
        transform: scaleX(107.5%);
    }

    .start__button:after {
        transition: all 0.2s ease;
        opacity: 0;
        content: url(../assets/Icons/arrow-forward.svg);
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.5rem;
    }

    .start__button:hover:after {
        transform: translateX(0.5rem);
        opacity: 1;
    }

    .img--start {
        grid-area: 1 / 2 / 6 / 3;
        margin: 0;
        height: 100%;
    }

    .about {
        display: grid;
        grid: auto auto 1fr / 1fr 1fr;
        column-gap: 2rem;
    }

    .about__headline {
        grid-area: 1 / 1 / 1 / 1;
    }

    .about__p1 {
        grid-area: 2 / 1 / 2 / 1;
    }

    .about__p2 {
        grid-area: 3 / 1 / 3 / 1;
        margin: 0 0 2rem 0;
    }

    .img--about {
        grid-area: 1 / 2 / 4 / 3;
        margin: 0;
        height: 100%;
    }

    .img--contact {
        margin: 0;
        height: 100%;
    }

    .contact {
        display: flex;
        flex-flow: row-reverse wrap;
    }

    .contact__content {
        flex: 1 1 45%;
        align-self: stretch;
    }

    .contact__content:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 2rem 0 0;
    }

    .contact__map {
        margin: 0;
        height: 100%;
    }

    .contact-list {
        margin: 0 0 2rem 0;
    }

    .footer {
        grid-area: foot;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .aside {
        bottom: 0;
    }

    .img--no-match {
        width: auto;
        max-height: 27.5rem;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 92.5rem) {
    .header__content {
        padding: 0.875rem calc((100% - 89.5rem) / 2);
    }

    .aside {
        padding: 2rem calc((100% - 89.5rem) / 2);
    }

    .section,
    .footer {
        padding: 6.25rem calc((100% - 89.5rem) / 2);
    }

    .privacy-policy {
        padding: 6.25rem calc((100% - 89.5rem) / 2) 0 calc((100% - 89.5rem) / 2);
    }

    .nav {
        padding: 0;
    }

    .img--about {
        max-height: 21.25rem;
    }
}
