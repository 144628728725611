.aside {
    position: fixed;
    bottom: 5.5rem;
    left: 0;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 0 3px rgba(0, 0, 0, 16%);
    padding: 2rem 1.5rem;
    background: var(--bg-color);
    text-align: center;
}

.aside--hidden {
    transform: translateY(50rem);
    overflow: hidden;
    box-sizing: border-box;
}