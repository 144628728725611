.card {
    position: relative;
    border: 1px solid var(--border-color);
    border-radius: 28px;
    box-sizing: border-box;
}

.card:not(:last-child) {
    margin: 0 0 1.75rem 0;
}

.card__section {
    padding: 1rem 1rem 0.25rem 1rem;
}

.card__badge {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    padding: 0.5rem 1rem;
    background: rgb(var(--primary-color));
    text-transform: uppercase;
    color: var(--text-on-primary-color);
}

.card__badge--extra {
    border: 1px solid var(--border-color);
    background: var(--bg-color);
    color: var(--text-on-bg-color);
}